import React from 'react';

import Popup from '../Popup';
import useTranslate from '../../hooks/useTranslate';

import './ImagesPopupWarn.scss';

const Images = ({ onClose, handleBtnClick }) => {
  const { t } = useTranslate();
  return (
    <Popup onClose={onClose}>
      <div className="imagesPopupTitle">{t('warning.title')}</div>
      <div className="imagesPopupText">{t('warning.info')}</div>
      <div className="imagesPopupQue">{t('warning.que')}</div>
      <div className="imagesPopupBtns">
        <div onClick={() => handleBtnClick(true)}>{t('warning.yes')}</div>
        <div onClick={() => handleBtnClick(false)}>{t('warning.no')}</div>
      </div>
    </Popup>
  );
};

export default Images;
