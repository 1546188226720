import warn from './warning.jpg';
import img10 from './1.jpg';
import img11 from './1 (1).jpg';
import img12 from './1 (2).jpg';
import img13 from './1 (3).jpg';
import img14 from './2 (1).jpg';
import img15 from './3.jpg';
import img16 from './7.jpg';
import img17 from './9. Photo_ Oleksandr Ratushnyak.jpg';
import img18 from './13.jpg';
import img19 from './14.jpg';
import img20 from './16.jpg';
import img21 from './18.jpg';
import img22 from './19.jpg';
import img23 from './21.jpg';
import img24 from './23.jpg';
import img25 from './24.jpg';
import img26 from './35.jpg';
import img27 from './36.jpg';
import img28 from './41.jpg';
import img29 from './43.jpg';
import img30 from './44.jpg';
import img31 from './by Ignat Ivlev-York.jpg';
import img32 from './ezgif.com-gif-maker.jpg';
import img33 from './Max Trebukhov, LB.ua.jpeg';
import img34 from './Mykhail Palinchak.jpg';
import img35 from './Mykhailo Palinchak (1).jpg';
import img36 from './Oleksandr Ratushnyak.jpg';
import img37 from './Oleksandr Ratushnyak (1).jpg';
import img38 from './Oleksandr Ratushnyak (2).jpg';
import img39 from './Rodrigo Abd, AP.jpg';
import img40 from './Ronaldo Schemidt.jpg';
import img41 from './Ronaldo Shemidt.jpg';
import img42 from './Stas Kozlyuk, Babel(1) Крупный.jpeg';
import img43 from './sts.jpeg';
import img44 from './Vadim Ghirda.jpg';
import img45 from './Zohra Bensemra, Reuters.jpg';
import img46 from './Zohra Bensemra, Reuters (1).jpg';
import img47 from './Zohra Bensemra, Reuters (2).jpg';
import img48 from './Zohra Bensemra, Reuters (3).jpg';
import img49 from './Zohra Bernsemra, Reuters.jpg';

export default [
  {
    img: warn,
    alt: 'Warning: terrifying images ahead',
  },
  {
    img: img46,
    alt: '',
  },
  {
    img: img41,
    alt: '',
  },
  {
    img: img12,
    alt: '',
  },
  {
    img: img13,
    alt: '',
  },
  {
    img: img14,
    alt: '',
  },
  {
    img: img15,
    alt: '',
  },
  {
    img: img32,
    alt: '',
  },
  {
    img: img16,
    alt: '',
  },
  {
    img: img17,
    alt: '',
  },
  {
    img: img18,
    alt: '',
  },
  {
    img: img19,
    alt: '',
  },
  {
    img: img20,
    alt: '',
  },
  {
    img: img42,
    alt: '',
  },
  {
    img: img43,
    alt: '',
  },
  {
    img: img10,
    alt: '',
  },
  {
    img: img11,
    alt: '',
  },
  {
    img: img21,
    alt: '',
  },
  {
    img: img22,
    alt: '',
  },
  {
    img: img23,
    alt: '',
  },
  {
    img: img24,
    alt: '',
  },
  {
    img: img25,
    alt: '',
  },
  {
    img: img26,
    alt: '',
  },
  {
    img: img27,
    alt: '',
  },
  {
    img: img28,
    alt: '',
  },
  {
    img: img29,
    alt: '',
  },
  {
    img: img30,
    alt: '',
  },
  {
    img: img31,
    alt: '',
  },
  {
    img: img33,
    alt: '',
  },
  {
    img: img34,
    alt: '',
  },
  {
    img: img35,
    alt: '',
  },
  {
    img: img36,
    alt: '',
  },
  {
    img: img37,
    alt: '',
  },
  {
    img: img38,
    alt: '',
  },
  {
    img: img39,
    alt: '',
  },
  {
    img: img40,
    alt: '',
  },
  {
    img: img44,
    alt: '',
  },
  {
    img: img45,
    alt: '',
  },
  {
    img: img47,
    alt: '',
  },
  {
    img: img48,
    alt: '',
  },
  {
    img: img49,
    alt: '',
  },
  {
    img: 'https://imagedelivery.net/KBcxbGGnx66TLOCjphpfOg/cde58bb3-6ec6-4d30-4d25-c7f5eba11d00/1200',
    alt: '',
  },
  {
    img: 'https://imagedelivery.net/KBcxbGGnx66TLOCjphpfOg/16e801cf-f61d-4486-d932-0411f53e6a00/1200',
    alt: '',
  }
];
