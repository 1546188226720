import React from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowRight } from './img/arrow-right.svg';
import { ReactComponent as ArrowLeft } from './img/arrow-left.svg';

import './Arrows.scss';

const Arrows = ({
  classPrefix,
  onClickPrev,
  onClickNext,
  prevDisabled,
  nextDisabled,
  children,
}) => {
  return (
    <div className={cn('arrows', `${classPrefix}Arrows`)}>
      <div
        className={cn('arrowsPrev', `${classPrefix}ArrowsPrev`, {
          'arrowsPrevDisabled': prevDisabled,
        })}
        onClick={() => onClickPrev()}
      >
        <ArrowLeft />
      </div>
      {children}
      <div
        className={cn('arrowsNext', `${classPrefix}ArrowsNext`, {
          'arrowsNextDisabled': nextDisabled,
        })}
        onClick={() => onClickNext()}
      >
        <ArrowRight />
      </div>
    </div>
  );
};

export default Arrows;
