import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import Cookies from 'js-cookie';

import Section from '../ContentSection';
import Arrows from '../Arrows';

import { ReactComponent as IconClose } from './close.svg';
import { ReactComponent as IconLook } from './look.svg';

import IMAGES_AGGRESSION from './imgUA/images';
import IMAGES_BUCHA from './bucha/images';

import useTranslate from '../../hooks/useTranslate';
import ImagesPopupWarn from './ImagesPopupWarn';
import Button from '../Button';

import './ImagesFrom.scss';
import { isServer } from '../../hooks';

export const RUSSIA_PHOTOS = 'russia_aggression';
export const BUCHA_PHOTOS = 'bucha';

const Images = ({ type, customGallery = null }) => {
  const location = useLocation();
  const content = {
    [RUSSIA_PHOTOS]: {
      title: `${location.pathname === '/' ? 'main' : 'war'}.photos`,
      info: location.pathname === '/' ? '' : 'war.photosInfo',
      imgSet: IMAGES_AGGRESSION,
      id: 'russian-invasion-photos',
    },
    [BUCHA_PHOTOS]: {
      title: 'war.bucha',
      info: 'war.buchaSubtitle',
      subinfo: 'war.buchaInfo',
      btnLink: '/bucha-massacre',
      imgSet: IMAGES_BUCHA,
      id: 'bucha-massacre',
      warn: true,
    },
  };

  const [currentIdx, setCurrentIdx] = useState(0);
  const [listIdx, setListIdx] = useState(0);
  const [limit, setLimit] = useState(6);
  const [isPreview, setPreview] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [event, setEvent] = useState(null);

  if (customGallery !== null) {
    content[type] = customGallery;
  }

  const { t } = useTranslate();

  const handleArrowClick = (to) => {
    const curIdx = currentIdx;

    switch (to) {
      case 'next':
        setCurrentIdx(val => val + 1);
        if (curIdx === listIdx + limit - 1) {
          setListIdx(val => val + 1);
        }
        break;
      case 'prev':
        setCurrentIdx(val => val - 1);
        if (curIdx === listIdx) {
          setListIdx(val => val - 1);
        }
        break;
    }
  };

  useEffect(() => {
    if (isServer || window.innerWidth > 580) {
      setLimit(6);
    } else {
      setLimit(4);
    }
  }, []);

  useEffect(() => {
    if (isServer) return;
    const bodyEl = document.querySelector('body');
    if (isPreview) {
      bodyEl.classList.add('--no-scroll');
    } else {
      bodyEl.classList.remove('--no-scroll');
    }
  }, [isPreview]);

  const handleImageClick = (e) => {
    if (isPreview) {
      e.stopPropagation();
    }
  };

  const handleWarnedClick = (fn) => {
    const isAgreed = Cookies.get('show-all-photos');
    if (isAgreed || !content[type].warn) {
      fn();
      return;
    }

    setEvent(() => fn);
    setShowPopup(true);
  };

  const handleBtnClick = (agreed) => {
    if (agreed) {
      Cookies.set('show-all-photos', true);
      event();
      setEvent(null);
    }
    setShowPopup(false);
  };

  return (
    <Section className="images">
      <div className={cn('imagesTitle', 'commonTitle')}>
        {content[type].title && t(content[type].title)}
        <a id={content[type].id} className="sub-anchor"></a>
      </div>
      {content[type].info && <div className={cn('imagesSubTitle', 'commonInfo')}>
        {t(content[type].info)}
      </div>}
      <div className="imagesContainer">
        <Arrows
          classPrefix={isPreview ? 'imagesFixed' : 'images'}
          onClickNext={() => handleWarnedClick(() => handleArrowClick('next'))}
          onClickPrev={() => handleArrowClick('prev')}
          nextDisabled={currentIdx + 1 === content[type].imgSet.length}
          prevDisabled={currentIdx === 0}
        />
        <div className="imagesThumbs">
          {content[type].imgSet.slice(listIdx, listIdx + limit).map((i, idx) => (
            <div
              className={cn('imagesThumb', {
                'imagesThumbSm': limit === 4,
              })}
              key={idx + listIdx}
              onClick={() => handleWarnedClick(() => setCurrentIdx(idx + listIdx))}
            >
              <div className="imagesMask">
                <IconLook />
              </div>
              <img src={i.thumb || i.img} alt={i.alt || t(content[type].title)} loading="lazy" />
            </div>
          ))}
        </div>
        {isPreview && <IconClose onClick={() => setPreview(false)} className="imagesClose" />}
        <div
          className={cn('imagesPreview', {
            '--full-screen': isPreview,
          })}
          onClick={() => setPreview(val => !val)}
        >
          <img
            src={content[type].imgSet[currentIdx].img}
            alt={content[type].imgSet[currentIdx].alt || t(content[type].title)}
            onClick={e => handleImageClick(e)}
          />
        </div>
      </div>
      {content[type].subinfo && <div className={cn('commonInfo', 'commonSubInfo')}>
        <span>{t(content[type].subinfo)}</span>
        {content[type].btnLink && <Button to={content[type].btnLink} red>
          {content[type].btnText || t('war.learnMore')}
        </Button>}
      </div>}

      {showPopup && <ImagesPopupWarn onClose={() => setShowPopup(false)} handleBtnClick={(bool) => handleBtnClick(bool)} />}
    </Section>
  );
};

export default Images;
