import img1 from './photo_2022-05-07 00.30.17.jpeg';
import img2 from './photo_2022-05-07 00.30.28.jpeg';
import img3 from './photo_2022-05-07 00.30.51.jpeg';
import img4 from './photo_2022-05-07 00.30.57.jpeg';
import img5 from './photo_2022-05-07 00.32.40.jpeg';
import img6 from './photo_2022-05-07 00.36.07.jpeg';
import img7 from './photo_2022-05-07 00.36.34.jpeg';
import img8 from './photo_2022-05-07 00.36.45.jpeg';
import img9 from './photo_2022-05-07 00.38.05.jpeg';
import img10 from './photo_2022-05-07 00.38.12.jpeg';
import img11 from './photo_2022-05-07 00.38.21.jpeg';
import img12 from './photo_2022-05-07 17.20.08.jpeg';
import smimg1 from './smphoto_2022-05-07 00.30.17.jpeg';
import smimg2 from './smphoto_2022-05-07 00.30.28.jpeg';
import smimg3 from './smphoto_2022-05-07 00.30.51.jpeg';
import smimg4 from './smphoto_2022-05-07 00.30.57.jpeg';
import smimg5 from './smphoto_2022-05-07 00.32.40.jpeg';
import smimg6 from './smphoto_2022-05-07 00.36.07.jpeg';
import smimg7 from './smphoto_2022-05-07 00.36.34.jpeg';
import smimg8 from './smphoto_2022-05-07 00.36.45.jpeg';
import smimg9 from './smphoto_2022-05-07 00.38.05.jpeg';
import smimg10 from './smphoto_2022-05-07 00.38.12.jpeg';
import smimg11 from './smphoto_2022-05-07 00.38.21.jpeg';
import smimg12 from './smphoto_2022-05-07 17.20.08.jpeg';

export default [
  {
    img: img1,
    thumb: smimg1,
    alt: 'The largest Ukrainian metallurgy enterprise in Kramatorsk burning after missile strike',
  },
  {
    img: img2,
    thumb: smimg2,
    alt: 'People crossing ruined bridge in Irpin during evacuation',
  },
  {
    img: img3,
    thumb: smimg3,
    alt: 'Residential house destroyed by russians\' shelling',
  },
  {
    img: img4,
    thumb: smimg4,
    alt: 'At least 25 civilians wounded after air and missile strikes in Kramatorsk',
  },
  {
    img: img5,
    thumb: smimg5,
    alt: '"Strategical military object" - wine producer warehouse near Bucha - is "100% destroyed"',
  },
  {
    img: img6,
    thumb: smimg6,
    alt: 'Houses destruction in Luhansk region',
  },
  {
    img: img7,
    thumb: smimg7,
    alt: 'Residential house in Kyiv - 4 people wounded and 1 dead',
  },
  {
    img: img8,
    thumb: smimg8,
    alt: 'Yet another russian\'s shelling of Ukrainian civilians',
  },
  {
    img: img9,
    thumb: smimg9,
    alt: 'Firefighters liquidating consequences of russian missile strike',
  },
  {
    img: img10,
    thumb: smimg10,
    alt: 'Partially destroyed, demolished building of the Kharkiv Regional Institute of Public Administration',
  },
  {
    img: img11,
    thumb: smimg11,
    alt: 'Karazin Kharkiv National University is hardly damaged by numerous missile strikes and shelling',
  },
  {
    img: img12,
    thumb: smimg12,
    alt: 'Destruction of civil objects in Kramatorsk, Ukraine',
  }
];
